import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import { Button } from 'src/components/atoms/Button';
import { GitHubButton } from 'src/components/atoms/GitHubButton';
import MDXComponents from 'src/components/mdx-components';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { Link } from 'src/containers/Link';

const ctaComponents = {
  Button,
  GitHubButton({ to, a11yText, children }) {
    return (
      <GitHubButton
        href={to}
        data-icon="octicon-star"
        data-show-count="true"
        data-size="large"
        aria-label={a11yText}
      >
        {children}
      </GitHubButton>
    );
  },
  Link,
};

export default function LegacySection({ heading, content, contentNode, ctas, customData }) {
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {!!ctas.length && (
          <InlineList centered>
            {ctas.map((cta, i) => {
              const Action = ctaComponents[cta.component || 'Button'];
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Action to={cta.url} key={i} {...ctaFlags}>
                    {cta.text}
                  </Action>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
